import Customer from "@/model/Customer";
import EmployeeName from "@/model/EmployeeName";
import {CostCenter} from "@/model/CostCenter";
import {OrderCustomerCategory} from "./OrderCustomerCategory";

export interface InventoryChecklistEntry {
  code: string;
  done: boolean;
  updateUser?: string;
  updateTime?: string;
}

export default class Order {
  public id!: number;

  public type: string | null = null;

  public validFrom = "";

  public validTo: string | null = null;

  public startTime: string | null = null;

  public name: string | null = null;

  public address: string | null = null;

  public zip: string | null = null;

  public city: string | null = null;

  public orderType: string | null = null;

  public customer: Customer | null = null;

  public checklist?: Array<InventoryChecklistEntry> | null = null;

  public teamLeader: EmployeeName | null = null;

  public notes?: string | null = null;

  public costCenter?: CostCenter | null = null;

  public positions?: number | null = null;

  public customerCategory: OrderCustomerCategory = {
    fruitAndVegetables: false,
    flowers: false,
    storage: false,
    targetActualComparison: false,
    labels: false,
    drinks: false
  };
}

export interface OrderSendMail extends Order {
  sendMail: boolean
}
