import {OrderType} from "@/enums";
import Order from "@/model/Order";
import CustomerService from "@/service/CustomerService";
import moment from "moment";
import PageUtils from "@/components/PageUtils";
import CustomerOrderDate from "@/views/Customers/model/CustomerOrderDate";

export const createDefaultInventoryOrder = (customerId: string | null = null, validFrom: string | null = null, validTo: string | null = null): Order => {
  const defaultInventoryOrder = new Order();
  if (customerId) {
    CustomerService.getCustomerById(Number(customerId))
      .then(customer => {
        defaultInventoryOrder.name = customer.name;
        if (customer.address) {
          defaultInventoryOrder.address = customer.address.street;
          defaultInventoryOrder.zip = customer.address.zip as unknown as string | null;
          defaultInventoryOrder.city = customer.address.city as unknown as string | null;
          defaultInventoryOrder.positions = customer.positions;
        }

        const customerCategory = {
          fruitAndVegetables: customer.og ?? false,
          flowers: customer.flowers ?? false,
          storage: customer.storage ?? false,
          targetActualComparison: customer.targetActualComparison ?? false,
          labels: customer.labels ?? false,
          drinks: customer.drinks ?? false,
        };

        defaultInventoryOrder.customerCategory = customerCategory;

        defaultInventoryOrder.customer = customer;
      });
  }

  defaultInventoryOrder.orderType = OrderType.INV;
  if (validFrom) {
    const validFromDate = moment(validFrom, "YYYY-MM-DD");
    if (!validFromDate.isBefore(moment())) {
      defaultInventoryOrder.validFrom = moment(validFrom).format("YYYY-MM-DD");
    } else {
      defaultInventoryOrder.validFrom = moment().format("YYYY-MM-DD");
    }
  } else {
    defaultInventoryOrder.validFrom = moment().format("YYYY-MM-DD");
  }

  if (validTo) {
    const validToDate = moment(validTo, "YYYY-MM-DD");
    if (!validToDate.isBefore(moment())) {
      defaultInventoryOrder.validTo = moment(validTo).format("YYYY-MM-DD");
    } else {
      defaultInventoryOrder.validTo = moment().format("YYYY-MM-DD");
    }
  } else {
    defaultInventoryOrder.validTo = moment().format("YYYY-MM-DD");
  }

  defaultInventoryOrder.startTime = "07:00";
  return defaultInventoryOrder;
};

export const updateInventoryOrderCustomer = (inventoryOrder: Order = new Order(), customerId: string | null = null): void => {
  if (customerId) {
    CustomerService.getCustomerById(Number(customerId))
      .then(customer => {
        inventoryOrder.name = customer.name;
        inventoryOrder.address = customer.address.street;
        inventoryOrder.zip = customer.address.zip as unknown as string | null;
        inventoryOrder.city = customer.address.city as unknown as string | null;
        const customerCategory = {
          fruitAndVegetables: customer.og ?? false,
          flowers: customer.flowers ?? false,
          storage: customer.storage ?? false,
          targetActualComparison: customer.targetActualComparison ?? false,
          labels: customer.labels ?? false,
          drinks: customer.drinks ?? false,
        };
        inventoryOrder.customerCategory = customerCategory;
        inventoryOrder.customer = customer;
      });
  }
};

export const updatePositions = (inventoryOrder: Order = new Order(), customerId: string | null = null): void => {
  if (customerId) {
    CustomerService.getOrders(Number(customerId))
      .then(response => {
        const pastOrders = PageUtils.extractCollection<CustomerOrderDate>(response.data, "past");
        inventoryOrder.positions = pastOrders[0].numberOfPositions;
      });
  }
};
