
  import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch} from "vue";
  import OrderInfoCustomer from "@/views/Orders/Info/OrderInfoCustomer.vue";
  import OrderInfoData from "@/views/Orders/Info/OrderInfoData.vue";
  import OrderInfoGeneral from "@/views/Orders/Info/OrderInfoGeneral.vue";
  import Order, {OrderSendMail} from "@/model/Order";
  import useVuelidate from "@vuelidate/core";
  import {isOrderCreateMode, orderId} from "@/RouterUtils";
  import {OrderType, OrderTypes} from "@/enums";
  import OrderService from "@/service/OrderService";
  import {loading, loadingFinished, success} from "@/MessageUtils";
  import {required} from "@/validation/i18n-validators";
  import CustomerService from "@/service/CustomerService";
  import OrderInfoNotes from "@/views/Orders/Info/OrderInfoNotes.vue";
  import store from "@/store/store";
  import {isAuthorized, t} from "@/mixin/mixins";
  import {useRoute, useRouter} from "vue-router/composables";
  import moment from "moment";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import {integer, minValue} from "@vuelidate/validators";
  import OrderInfoCostCenter from "@/views/Orders/Info/OrderInfoCostCenter.vue";
  import OrderInfoCategoryCard from "@/views/Orders/Info/OrderInfoCategoryCard.vue";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import ConfirmDialogImpl from "@/components/ConfirmDialog.vue";
  import OrderInfoTasks from "@/views/Orders/Info/OrderInfoTasks.vue";

  const toOrderType = (orderTypeString: string) => {
    return OrderTypes.find(item => item.code === orderTypeString);
  };

  export default defineComponent({
    name: "OrderInfo",
    components: {
      ConfirmDialog: ConfirmDialogImpl,
      OrderInfoCostCenter,
      OrderInfoCustomer,
      OrderInfoData,
      OrderInfoGeneral,
      OrderInfoNotes,
      OrderInfoTasks,
      OrderInfoCategoryCard
    },
    setup(props, context) {
      const confirmDialog = ref<InstanceType<typeof ConfirmDialogImpl>>();

      const route = useRoute();
      const router = useRouter();

      // Needed so the customer watcher does not fire on the first population of the data
      let isDataInitialized = false;

      const initialState = {
        originalData: new Order(),
        currentData: new Order(),
        loading: false,
        dirty: false,
        sendMail: true,
        serverErrors: [],
      };

      const state = reactive({...initialState});

      const isMarket = computed(() => {
        if (state.currentData.orderType != null) {
          let orderType = toOrderType(state.currentData.orderType);
          return !orderType?.project;
        } else {
          return false;
        }
      });

      const rules = {
        currentData: {
          name: {
            required
          },
          address: {
            required
          },
          zip: {
            required
          },
          city: {
            required
          },
          orderType: {
            required
          },
          validFrom: {
            required
          },
          validTo: {
            required
          },
          startTime: {
            required
          },
          positions: {
            integer,
            minValue: minValue(1)
          }
        }
      };

      const v$ = useVuelidate(rules, state);

      const errorMap = computed(() => {
        const map = new Map<string, string>();
        // @ts-ignore
        v$.value.$errors.forEach(error => map.set(error.$property, error.$message as string));
        return map;
      });

      const isEditable = computed(() => {
        return isOrderCreateMode.value || isAuthorized("Order_Overview_EditOrders") || isAuthorized("Superadmin");
      });

      const loadOrder = async () => {
        loading("order", true);
        let response = await OrderServiceV2.getOrder(orderId.value);
        state.originalData = response.data;
        loadingFinished("order");
      };

      const save = () => {
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          state.currentData.type = isMarket.value ? "Market" : "Project";
          const request: OrderSendMail = {
            ...state.currentData,
            sendMail: state.sendMail
          };
          const promise = (isOrderCreateMode.value) ? OrderService.createOrder(request)
            : OrderService.updateOrder(orderId.value, request);
          promise
            .then(response => {
              state.dirty = false;
              state.originalData = response.data;
              success(t("saveSuccessful"));

              if (isOrderCreateMode) {
                nextTick(() => {
                  router.push({path: `/orders/${response.data.id}/info`});
                });
              }
            })
            .catch(error => {
              if (error.response.statusMessage === 422) {
                state.serverErrors = error.response.data;
              }
            });
        }
      };

      const deleteOrder = () => {
        const title = t("order.orderInfo.deleteOrderConfirmationDialog.title");
        const text = t("order.orderInfo.deleteOrderConfirmationDialog.text");
        confirmDialog.value?.open(title, text)
          .then(confirmResult => {
            if (confirmResult) {
              OrderServiceV2.deleteOrder(orderId.value)
                .then(() => {
                  eventBus.$emit(MessageTypes.SUCCESS, t("order.orderInfo.orderDeleted"));
                  router.app.$router.push("/v2/orders");
                });
            }
          });
      };
      const revertChanges = () => {
        v$.value.$reset();
        state.currentData = JSON.parse(JSON.stringify(state.originalData));
        nextTick(() => {
          state.dirty = false;
        });
      };

      watch(() => state.originalData, (newValue, oldValue) => {
        state.currentData = Object.assign(new Order(), JSON.parse(JSON.stringify(newValue)));
        nextTick(() => {
          state.dirty = false;
          isDataInitialized = true;
        });
      }, {deep: true});

      watch(() => state.currentData, (newValue, oldValue) => {
        if (Object.keys(oldValue).length > 0) {
          state.dirty = true;
        }
      }, {deep: true});

      watch(() => state.currentData.customer, (newValue, oldValue) => {
        let isCreateMode = isOrderCreateMode.value;
        if (isDataInitialized || isCreateMode) {
          // Customer changed/added
          if (newValue != null && (oldValue == null || oldValue.id != newValue.id)) {
            CustomerService.getCustomerById(newValue.id)
              .then(customer => {
                state.currentData.name = customer.name;
                state.currentData.address = customer.address.street;
                state.currentData.zip = customer.address.zip as unknown as string | null;
                state.currentData.city = customer.address.city as unknown as string | null;
                state.currentData.positions = customer.positions;

                const customerCategory = {
                  fruitAndVegetables: customer.og ?? false,
                  flowers: customer.flowers ?? false,
                  storage: customer.storage ?? false,
                  targetActualComparison: customer.targetActualComparison ?? false,
                  labels: customer.labels ?? false,
                  drinks: customer.drinks ?? false,
                };

                state.currentData.customerCategory = customerCategory;
                // In createMode we might not have filled all required fields yet
                if (!isCreateMode) {
                  save();
                }
              });
          // Customer removed
          } else if (newValue == null && oldValue != null) {
            save();
          }
        }
      });

      watch(() => state.currentData.name, (newValue, oldValue) => {
        store.commit("header/setTitle", newValue);
      });

      onMounted(() => {
        if (!isOrderCreateMode.value) {
          loadOrder();
        } else {
          if (router.app.$route.query.customerId) {
            CustomerService.getCustomerById(Number(router.app.$route.query.customerId))
              .then(customer => {
                state.originalData.name = customer.name;
                if (customer.address) {
                  state.originalData.address = customer.address.street;
                  state.originalData.zip = customer.address.zip as unknown as string | null;
                  state.originalData.city = customer.address.city as unknown as string | null;
                  state.originalData.positions = customer.positions;
                }

                const customerCategory = {
                  fruitAndVegetables: customer.og ?? false,
                  flowers: customer.flowers ?? false,
                  storage: customer.storage ?? false,
                  targetActualComparison: customer.targetActualComparison ?? false,
                  labels: customer.labels ?? false,
                  drinks: customer.drinks ?? false,
                };

                state.originalData.customerCategory = customerCategory;

                state.originalData.customer = customer;
              });
          }

          state.originalData.orderType = OrderType.INV;
          state.originalData.validFrom = moment().format("YYYY-MM-DD");
          state.originalData.validTo = moment().format("YYYY-MM-DD");
          state.originalData.startTime = "07:00";
        }
      });

      return {
        ...toRefs(state),
        v$,
        confirmDialog,
        errorMap,
        isEditable,
        isMarket,
        isOrderCreateMode,
        save,
        deleteOrder,
        revertChanges,
        loadOrder
      };
    }
  });
