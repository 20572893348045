import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VTabs,{attrs:{"color":"primary","centered":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,{key:0},[_vm._v(" "+_vm._s(_vm.t("inventories.tabs.tasks"))+" ")]),_c(VTabItem,[_c('InventoriesTasks')],1),(_vm.isAuthorized('Inventory_EditEmployeeCompetencies'))?_c(VTab,{key:1},[_vm._v(" "+_vm._s(_vm.t("inventories.tabs.competencies"))+" ")]):_vm._e(),(_vm.isAuthorized('Inventory_EditEmployeeCompetencies'))?_c(VTabItem,[_c('InventoriesCompetencies')],1):_vm._e(),(_vm.isAuthorized('Inventory_EditAdministration'))?_c(VTab,{key:2},[_vm._v(" "+_vm._s(_vm.t("inventories.tabs.administration"))+" ")]):_vm._e(),(_vm.isAuthorized('Inventory_EditAdministration'))?_c(VTabItem,[_c(VTabs,{attrs:{"color":"primary","centered":""}},[_c(VTab,[_vm._v(" "+_vm._s(_vm.t("inventories.tabs.subtabs.companyLocations"))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.t("inventories.tabs.subtabs.marketAreas"))+" ")]),_c(VTabItem,[_c('InventoriesCompanyLocations')],1),_c(VTabItem,[_c('InventoriesMarketAreas')],1)],1)],1):_vm._e(),(_vm.isAuthorized('Inventory_ViewPerformance'))?_c(VTab,{key:3},[_vm._v(" "+_vm._s(_vm.t("inventories.tabs.countingPerformance"))+" ")]):_vm._e(),(_vm.isAuthorized('Inventory_ViewPerformance'))?_c(VTabItem,{nativeOn:{"touchend":function($event){$event.stopPropagation();}}},[_c('InventoriesCountingPerformance')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }