
  import {defineComponent, ref, inject, onMounted} from "vue";
  import {t} from "@/mixin/mixins";
  import OrderInfoCard from "@/views/Orders/Info/OrderInfoCard.vue";
  import {OrderTaskImport, OrderTask} from "@/model/OrderTaskImport";
  import {orderId} from "@/RouterUtils";
  import {employeeId} from "@/store/StoreUtils";
  import OrderTaskService from "@/service/OrderTaskService";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import FilterUtils from "@/FilterUtils";

  export default defineComponent({
    name: "OrderInfoTasks",
    components: {
      OrderInfoCard
    },
    setup() {
      let fileInput = ref<HTMLInputElement | null>(null);
      const currentImport = ref<OrderTaskImport | null>(null);

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;

      onMounted(() => {
        loadImport();
      });

      const loadImport = () => {
        const id = orderId.value;
        if(FilterUtils.isNum(id)) {
          OrderTaskService.getOrderTaskImportByOrderId(id)
            .then(response => {
              if (response.data) {
                currentImport.value = response.data;
              }
            })
            .catch(error => { });
        }
      };

      const onImport = () => {
        fileInput?.value?.click();
      };

      const onDelete = () => {
        const title = t("order.orderInfo.tasks.deleteTasks");
        const text = t("order.orderInfo.tasks.deleteTasksText");
        confirmDialog.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              deleteCurrentImport();
            }
          });
      };

      const readFile = (event: Event) => {
        const fileInput = event?.target as HTMLInputElement;
        const file = fileInput?.files?.[0];
        if (file && file.type === "text/csv") {
          readFileEncoding(file).then((csvContent) => {
            const rows = csvContent.split("\n").filter(row => row.trim() !== "");

            const orderTaskImport: OrderTaskImport = {
              orderId: orderId.value,
              employeeId: parseInt(employeeId.value),
              date: new Date().toISOString(),
              tasks: []
            };

            rows.slice(1).forEach((row) => {
              const values = row.split(";").map(value => value.trim());
              if (values.every(value => value === "")) return;
              const orderTask: OrderTask = {
                label: values[0],
                positions: parseInt(values[1], 10) || 0,
                employeeName: values[2],
                employeeId: parseInt(values[3], 10) || 0,
                priority: parseInt(values[4], 10) || 0
              };
              orderTaskImport?.tasks?.push(orderTask);
            });
            handleImport(orderTaskImport);
            fileInput.value = "";
          });
        } else {
          eventBus.$emit(
            MessageTypes.INFO,
            t("order.orderInfo.tasks.importTasksErrorTitle"),
            t("order.orderInfo.tasks.importTasksErrorText")
          );
        }
      };

      const readFileEncoding = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            const buffer = event.target?.result as ArrayBuffer;
            const utf8Decoder = new TextDecoder("utf-8");
            const utf8Text = utf8Decoder.decode(buffer);
            if (containsInvalidUtf8Characters(utf8Text)) {
              console.warn("UTF-8 failed, attempting ISO 8859-1...");
              const isoDecoder = new TextDecoder("iso-8859-1");
              const isoText = isoDecoder.decode(buffer);
              resolve(isoText);
            } else {
              resolve(utf8Text);
            }
          };
          reader.onerror = () => reject(new Error("File reading error"));
          reader.readAsArrayBuffer(file);
        });
      };

      const containsInvalidUtf8Characters = (text: string): boolean => {
        return [...text].some(char => char === "\ufffd");
      };

      const handleImport = (newImport: OrderTaskImport) => {
        if (newImport != null) {
          deleteCurrentImport().then(() => {
            createImport(newImport);
          });
        }
      };

      const createImport = (newImport: OrderTaskImport) => {
        OrderTaskService.createOrderTaskImport(newImport)
          .then(response => {
            if (response.status === 200) {
              currentImport.value = response.data;
            }
          })
          .catch(() => {
            eventBus.$emit(
              MessageTypes.INFO,
              t("order.orderInfo.tasks.importTasksErrorTitle"),
              t("order.orderInfo.tasks.importTasksErrorText")
            );
          });
      };

      const deleteCurrentImport = (): Promise<any> => {
        return new Promise((resolve) => {
          if (currentImport?.value?.id) {
            OrderTaskService.deleteOrderTaskImport(currentImport?.value?.id as number)
              .then(response => {
                currentImport.value = null;
                resolve(response);
              })
              .catch(error => { });
          } else {
            resolve({ status: "no_action", message: "No valid import to delete" });
          }
        });
      };

      return {
        onImport,
        readFile,
        onDelete,
        fileInput,
        currentImport
      };
    }
  });
