import {de} from "vuetify/src/locale";

export default {
  ...de,

  accessDenied: "Zugriff verweigert!",
  accounting: {
    monthClosed: "Monat abgerechnet",
    numberOfHours: "Anz. Stunden",
    title: "Abrechnung",
    wageOrSalary: "Stundenlohn / Gehalt",
    perDiems: {
      cateringPerDiem: "Verpflegung",
      miscPerDiem: "Sonstiges",
      perDiemsSet: "Zuschüsse gesetzt",
      showPotentialPerDiems: "Mögliche Zuschüsse anzeigen"
    }
  },
  amount: "Betrag",
  apply: "Anwenden",
  automaticPause: "Automatische Pause",
  average: "Durchschnitt",
  cancel: "Abbrechen",
  checkInput: "Bitte Eingaben prüfen",
  comment: "Kommentar",
  confirm: "Bestätigen",
  confirmed: "Bestätigt",
  delete: "Löschen",
  duration: "Dauer",
  end: "Ende",
  inventoryBonusEligible: "Inventurprämienberechtigt",
  from: "Von",
  loading: "Lade..",
  overview: "Übersicht",
  revert: "Verwerfen",
  save: "Speichern",
  selectAll: "Alle auswählen",
  since: "Seit",
  skip: "Überspringen",
  sortBy: "Sortieren nach",
  start: "Start",
  to: "Bis",
  confirmDialog: {
    delete: {
      title: "Eintrag löschen",
      text: "Soll der Eintrag wirklich gelöscht werden?"
    }
  },
  entry: {
    create: {
      title: "Eintrag erstellen"
    },
    edit: {
      title: "Eintrag bearbeiten"
    }
  },
  file: {
    delete: {
      success: "Datei gelöscht",
      error: "Datei konnte nicht gelöscht werden"
    },
    upload: {
      success: "Datei hochgeladen",
      error: "Datei konnte nicht hochgeladen werden",
      "error-exactly-one": "Es muss genau eine *.csv Datei hochgeladen werden",
      fileTooBig: "Die Datei ist zu groß (max. 2MB)"
    },
    confirmDelete: {
      title: "Datei löschen",
      text: "Soll die Datei wirklich gelöscht werden?"
    }
  },
  stepper: {
    previous: "Zurück",
    next: "Weiter"
  },
  addingEntry: "Füge Eintrag hinzu",
  entryAdded: "Eintrag hinzugefügt",
  updatingEntry: "Eintrag aktualisiert",
  entryUpdated: "Eintrag aktualisiert",
  deletingEntry: "Lösche Eintrag",
  entryDeleted: "Eintrag gelöscht",
  roleAdded: "Rolle hinzugefügt",
  roleRemoved: "Rolle entfernt",
  saveSuccessful: "Speichern erfolgreich",
  cashFlow: {
    createCashFlow: "CashFlow erstellen",
    editCashFlow: "CashFlow bearbeiten"
  },
  citizenship: {
    birthplace: "Geburtsort",
    countryOfBirth: "Geburtsland",
    citizenship: "Staatsangehörigkeit",
    general: "Staatsbürgerschaft",
    citizenshipQuestion: "Sind Sie EU-Bürger?",
    euCitizen: "EU-Bürger",
    residencePermitValidTo: "Aufenthaltserlaubnis bis",
    passportValidTo: "Passgültigkeit bis",
    workPermitValidTo: "Arbeitserlaubnis bis",
    workPermitRequiredHint: "Sie müssen zum Arbeitsbeginn eine Arbeitserlaubnis vorweisen können."
  },
  costCenter: {
    lookup: {
      placeholder: "Kostenstelle"
    }
  },
  customer: {
    customerOverview: "Kundenübersicht",
    title: "Kunde",
    name: "Name",
    customerType: "Kundentyp",
    inactiveCustomer: "Inaktiver Kunde",
    lookup: {
      title: "Kundensuche",
      placeholder: "Kundenname"
    },
    tabs: {
      overview: "Übersicht",
      appointments: "Termine"
    },
    confirmDelete: {
      title: "Kunde löschen",
      text: "Soll der Kunde wirklich gelöscht werden?"
    },
    details: {
      travelTimes: {
        title: "Anfahrtszeiten"
      }
    },
    orders: {
      upcoming: {
        title: "Termine",
        create: "Neuen Auftrag anlegen",
        plan: "Im Kalender planen",
        planPos: "Zu planende Positionen"
      },
      past: {
        title: "Vergangene Termine"
      }
    }
  },
  description: "Beschreibung",
  employee: {
    create: "Mitarbeiter anlegen",
    created: "Mitarbeiter erstellt",
    createError: "Mitarbeiter konnte nicht erstellt werden.",
    deactivate: "Mitarbeiter deaktivieren",
    employeeId: "Mitarbeiter-ID",
    employeeCount: "Mitarbeiteranzahl",
    employment: "Beschäftigung",
    employmentHistory: "Beschäftigungshistorie",
    showEmploymentHistory: "Beschäftigungshistorie anzeigen",
    export: "Export",
    firstAid: "Erste Hilfe",
    firstAidCourseDate: "Erste Hilfe von",
    firstAidValidUntil: "Erste Hilfe bis",
    manager: "Leitender Angestellter",
    operatorNumber: "Bedienernummer",
    companyLocation: "Standort",
    personal: "Persönliches",
    reactivate: "Mitarbeiter reaktivieren",
    searchEmployee: "Mitarbeiter suchen",
    showInactiveEmployees: "Inaktive Mitarbeiter anzeigen",
    teamlead: "Teamleiter",
    teamleadSince: "Teamleiter seit",
    wage: "Gehalt",
    welcomePdf: "Willkommens-PDF drucken",
    lookup: {
      title: "Mitarbeitersuche",
      placeholder: "Mitarbeitername",
      label: "Mitarbeiter"
    },
    deactivationDialog: {
      title: "Mitarbeiter deaktivieren",
      text: "Soll der/die Mitarbeiter/in <br><b> {0} {1}</b><br> deaktiviert werden?"
    },
    reactivationDialog: {
      title: "Mitarbeiter reaktivieren",
      text: "Soll der/die Mitarbeiter/in <br><b> {0} {1}</b><br> wieder aktiviert werden? <br><br> <b>Hinweis:</b> Das Vertragsenddatum muss ggfs. neu gesetzt werden!"
    },
    details: {
      competencies: {
        title: "Einsatzfelder",
        label: {
          allrounder: "Allrounder",
          quickCounter: "Schnellzähler",
          fruitAndVegetables: "O&G",
          flowers: "Floristik",
          storage: "Lager",
          storageManagement: "Lagerleitung",
          checkout: "Kasse",
          weigh: "Waage",
          reuse: "Mehrweg",
          check: "Prüfen",
          targetActualComparison: "Soll/IST"
        }
      }
    }
  },
  errors: {
    generalError: "Fehler! Bitte Admin kontaktieren"
  },
  expired: "Abgelaufen",
  birthdate: "Geburtsdatum",
  firstName: "Vorname",
  birthName: "Geburtsname",
  employmentType: "Beschäftigungsart",
  name: "Name",
  lastName: "Nachname",
  fullName: "Name",
  wageType: "Entlohnungsart",
  noDataText: "Keine Daten verfügbar",
  contractExpiryDate: "Vertragsenddatum",
  orderName: "Auftragsname",
  orders: "Aufträge",
  order: {
    title: "Auftrag",
    inventoryConfirmations: {
      bindingConfirmation: "Verbindlich bestätigen",
      changedByCustomer: "Von Kunde geändert",
      comment: "Möchten Sie uns noch etwas mitteilen?",
      confirmInventory: {
        title: "Inventur bestätigen",
        text: "Hiermit bestätige ich die Inventur.",
      },
      confirmed: "Bestätigt",
      confirmedOn: "Bestätigt am",
      content: "Auftragsinhalt",
      expired: "Die Inventur kann leider nicht mehr digital bestätigt werden. Bitte wenden Sie sich an",
      flowers: "Floristik",
      fruitAndVegetables: "Obst & Gemüse",
      inventoryConfirmed: "Sie haben die Inventur erfolgreich bestätigt. Vielen Dank.",
      inventoryAlreadyConfirmed: "Sie haben die Inventur bereits bestätigt. Vielen Dank.",
      labels: "Etiketten",
      pleaseConfirmData: "Bitte bestätigen Sie im Folgenden die bei uns hinterlegten Inventurdaten.",
      serviceNotOrderable: "Wenn Sie diese Dienstleistung buchen wollen, rufe Sie uns bitte an.",
      storage: "Lager",
      storageRollCageCount: "ca. # Rollis",
      storageRollCageCountText: "ca. Anzahl Rollis",
      storageFreezerRollCageCount: "ca. # TK Rollis",
      storageFreezerRollCageCountText: "ca. Anzahl TK Rollis",
      targetActualComparison: "Soll/Ist-Vergleich",
      title: "Inventurbestätigungen"
    },
    "open-in-new": "Öffne Auftrag in neuem Tab",
    orderInfo: {
      deleteOrderConfirmationDialog: {
        title: "Auftrag löschen",
        text: "Soll der Auftrag gelöscht werden?"
      },
      orderDeleted: "Auftrag gelöscht",
      costCenter: {
        title: "Kostenstelle",
        editCostCenter: "Kostenstelle bearbeiten",
      },
      customer: {
        title: "Kunde",
        addCustomer: "Kunde hinzufügen",
        removeCustomer: "Kunde entfernen",
        removeCustomerConfirmationDialog: {
          title: "Kunde entfernen",
          text: "Soll der Kunde entfernt werden?"
        }
      },
      data: {
        title: "Daten",
        orderType: "Auftragsart"
      },
      notes: {
        title: "Notizen",
        add: "Notizen hinzufügen",
        edit: "Notizen bearbeiten",
        updated: "Notizen aktualisiert"
      },
      category: {
        title: "Kunden Kategorien",
      },
      sendMail: "Bestätigungsmail senden",
      tasks: {
        title: "Aufgaben",
        lastImport: "Letzter Import",
        importTasks: "Aufgaben importieren",
        importTasksErrorTitle: "Import fehlgeschlagen",
        importTasksErrorText: "Bitte überprüfen Sie die Daten und laden Sie eine gültige CSV-Datei hoch.",
        deleteTasks: "Aufgaben löschen",
        deleteTasksText: "Wollen Sie die Aufgaben wirklich löschen?",
        noTasks: "Noch kein Import durchgeführt",
        misc: {
          oclock: "Uhr"
        }
      }
    },
    operators: {
      operatorNumber: "Bedienernummer",
      numberOfPositions: "Anzahl. Pos",
      positionsPerHour: "Positionen/h",
      table: {
        title: "Bedienerübersicht",
      },
      unavailable: "Es müssen erst die Fahrzeiten und Stunden kontrolliert werden"
    },
    tabs: {
      info: "Übersicht",
      worklogs: "Arbeitszeiten",
      assignments: "Zuweisung",
      perDiems: "Zuschüsse",
      operator: "Bediener",
      overview: "Übersicht",
      calendar: "Kalender",
      editHistory: "Bearbeitungshistorie",
      planning: "Planung"
    },
    calendar: {
      eventMoreText: "weitere Inventuren",
      plan: "Planung für:",
      planPos: "Benötigte Positionen",
      today: "Heute",
      customertype: "Kundentyp",
      filter: "Region VB KST",
      dialog: {
        title: "Termin anlegen",
        inventory: {
          tab: "Inventur anlegen",
          generalTitle: "Daten",
          dataTitle: "Auftrag",
          customerTitle: "Kunde",
        },
        note: {
          tab: "Notiz anlegen",
          date: "Datum",
          note: "Notiz"
        },
        maxPos: {
          title: "Tag anpassen",
          date: "Datum",
          maxPos: "Maximale Positionen",
          note: "Notiz"
        }
      },
      type: {
        month: "Monat",
        week: "Woche",
        day: "Tag",
        fourDays: "4 Tage"
      },
      categoryIcons: {
        drinks: "Extra GM",
        storage: "Lager",
        targetActualComparison: "Soll/IST",
        labels: "Etiketten",
        notes: "Notizen"
      }
    },
    orderHistory: {
      table: {
        header: {
          date: "Bearbeitungsdatum",
          employee: "Bearbeiter"
        },
        time: "Uhr"
      }
    }
  },
  remove: "Entfernen",
  search: "Suchen...",
  startTime: "Startzeit",
  validFrom: "Gültig von",
  validTo: "Gültig bis",
  validSince: "Gültig seit",
  errorCodes: {
    REGISTRATION_CODE_INVALID: "Ungültiger Registrierungscode"
  },
  validation: {
    greaterThanOrEqualTo: "{0} muss größer oder gleich {1} sein",
    invalid: "{0} ist ungültig",
    minValue: "{0} muss größer als {1} sein",
    numeric: "{0} ist keine Zahl",
    required: "{0} ist erforderlich",
    validDate: "Ungültiges Datum",
    invalidEmailFormat: "Ungültiges E-Mail Format"
  },
  // vuelidate 2
  validations: {
    required: "Wert ist erforderlich"
  },
  addressAndContactInfo: "Adress- und Kontaktdaten",
  address: {
    address: "Adresse",
    addressInfo: "Adressdaten",
    street: "Straße",
    zip: "PLZ",
    city: "Ort"
  },
  bankingInfo: "Bankverbindung",
  banking: {
    bic: "BIC",
    iban: "IBAN"
  },
  contact: {
    contactInfo: "Kontaktdaten",
    email: "E-Mail",
    phone: "Telefon/Handy"
  },
  date: {
    text: "Datum",
    day: "Tag",
    month: "Monat",
    year: "Jahr",
    start: "Anfang",
    end: "Ende",
    timerange: "Zeitraum",
    dateRange: {
      title: "Datumsbereich",
      relative: "Relativ",
      lastTwoWeeks: "Letzte 2 Wochen",
      nextTwoWeeks: "Nächste 2 Wochen",
      currentMonth: "Aktueller Monat",
      lastMonth: "Letzter Monat",
      currentYear: "Aktuelles Jahr",
      lastYear: "Letztes Jahr"
    }
  },
  time: {
    hours: "Stunden"
  },
  disability: "Schwerbehinderung",
  hint: "Hinweis",
  month: {
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember"
  },
  inventoryChecklist: {
    title: "Checklist",
    beforeInventory: "Vor der Inventur",
    duringInventory: "Während der Inventur",
    afterInventory: " Nach der Inventur",
    statusUpdateSuccess: "Status erfolgreich gesetzt",
    statusUpdateError: "Fehler beim Setzen des Status",
    doneText: "Abgeschlossen am {0} von {1}",
    codes: {
      10: "Mitarbeiter angelegt",
      11: "Fahrer zum Stempeln freigeschaltet",
      20: "Markt über Zufriedenheitsbefragung informiert",
      21: "Auf Fax mit Inventursummen hingewiesen",
      22: "Auf Lieferscheinverbuchung hingewiesen",
      23: "Krankheitsausfälle und Aushilfen in Teams",
      24: "Hinweis auf Lieferscheinverbuchung",
      25: "Verträge bearbeiten (Kontrolle, Kopie, Versand)",
      26: "Markt auf Inventursummen und Zufriedenheitsbefragung hinweisen",
      27: "Kundenkontakt des Marktes pflegen",
      30: "Anzahl Pos. und Std. in Zählertabelle eingetragen",
      31: "Kundenkontakt mit Std. und Infos vervollständigt",
      32: "Tätigkeitsbescheinigung ins Büro geschickt",
      33: "Fahrzeiten und Stunden kontrolliert",
      34: "Zuschüsse gesetzt",
      35: "Aushilfen bewerten und MA Einsatzfelder aktualisieren",
      36: "Produktivitätstabelle pflegen",
      37: "Tätigkeitsbescheinigung in OneDrive",
      38: "Qualitätsbewertung Rewe",
      39: "Bedienersummen eingetragen"
    }
  },
  perDiems: "Zuschüsse",
  registration: {
    deleteRegistration: {
      title: "Registrierung löschen",
      text: "Soll die Registrierung von <b>{0}</b> gelöscht werden?"
    },
    deletedRegistration: "Registrierung gelöscht",
    registrations: "Registrierungen",
    finishRegistration: "Registrierung abschließen..",
    finishedRegistration: "Registrierung abgeschlossen",
    finishConfirmationDialog: {
      title: "Registrierung abschließen",
      text: "Soll die Registrierung abgeschlossen werden?"
    },
    finished: {
      title: "Vielen Dank!",
      text1: "Die Registrierung ist nun abgeschlossen.",
      text2: "Folgende Dokumente sind ausgedruckt und unterschrieben mitzubringen."
    },
    education: {
      degree: {
        general: "Ausbildung",
        unknown: "Abschluss unbekannt",
        without: "Ohne beruflichen Ausbildungsabschluss",
        vocationalTraining: "Abschluss einer anerkannten Berufsausbildung",
        masterCraftsman: "Meister-/Techniker- oder gleichwertiger Fach",
        bachelor: "Bachelor",
        master: "Diplom/Magister/Master/Staatsexamen",
        doctorate: "Promotion"
      },
      education: {
        general: "Schulabschluss",
        unknown: "Abschluss unbekannt",
        without: "Ohne Schulabschluss",
        lowerSecondary: "Haupt-/Volksschulabschluss",
        intermediateSecondary: "Mittlere Reife oder gleichwertiger Abschluss",
        upperSecondary: "Abitur / Fachabitur"
      }
    },
    employment: {
      general: "Beschäftigungen",
      mainEmployer: "Ist GoodSigns der Hauptarbeitgeber?",
      otherEmployments: "Werden parallel weitere Beschäftigungen ausgeübt?",
      otherEmployerInCurrentYear: "Waren Sie im Laufe des Jahres bei einem weiteren Arbeitgeber beschäftigt?",
      daysShortTermEmployment: "Wie viele Tage wurde in dem aktuellen Kalenderjahr schon kurzfristigen Beschäftigungen nachgegangen?",
      days: "Tage"
    },
    shortTermEmploymentQuestionnaire: {
      general: "Fragebogen Kurzfristige Beschäftigung",
      onParentalLeave: "Sind sie zur Zeit in Elternzeit?",
      receivesStateBenefits: "Bekommen Sie zur Zeit Bezüge vom Amt?",
      unemployed: "Sind Sie Arbeitssuchend gemeldet?",
      inBetweenTrainings: "Befinden Sie sich gerade zwischen Zwei Ausbildungsabschnitten (Schule/Studium/Ausbildung)?",
      shortTermEmploymentUnavailable: "Leider ist für Sie ein kurzfristiges Beschäftigungsverhältnis nicht möglich.<br><br>Bitte wenden Sie sich an unser Büro um eine passende Anstellungsart zu besprechen.</br></br>Kontakt:<br>Ayse Sular a.sular@goodsigns.de</br>05231/3042911"
    },
    socialSecurity: {
      addChild: "Kind hinzufügen",
      child: "Kind",
      children: "Kinder",
      noChildren: "Keine Kinder",
      general: "Sozialversicherung",
      pensionInsuranceNumber: "Sozialversicherungsnummer",
      healthInsurance: "Name der Krankenkasse",
      healthInsuranceUpload: "Mitgliedsbescheinigung der Krankenkasse",
      healthInsuranceUploadRequired: "Eine Mitgliedsbescheinigung muss hochgeladen werden",
      pensionInsuranceVoluntaryPayment: "Möchten Sie freiwillig in die Rentenversicherung einzahlen?"
    },
    maritalStatus: {
      general: "Familienstand",
      single: "ledig",
      married: "verheiratet",
      widowed: "verwitwet",
      divorced: "geschieden",
      livingApart: "Getrennt lebend",
      living_apart: "Getrennt lebend",
      civilUnion: "Eingetr. Partnerschaft"
    },
    misc: {
      title: "Verschiedenes",
      disabled: "Sind Sie schwerbehindert?",
      disabledUpload: "Hier können Sie Ihren Schwerbehindertenausweis hochladen",
      student: "Sind Sie SchülerIn/StudentIn?",
      studentUpload: "Hier können Sie Ihren Nachweis hochladen",
      studentUploadRequired: "Eine Studienbescheinigung muss hochgeladen werden",
      selfEmployed: "Ich bin hauptberuflich selbständig tätig.",
      maxWeeklyHours: "Maximale Anzahl Arbeitsstunden pro Woche",
      maxMonthlyCompensation: "Maximaler Monatsverdienst in €",
      miniJobCompensation: "Ich habe einen Minijob mit folgendem Monatsverdienst (€)",
      optional: {
        description: "Die folgenden Angaben sind optional und nur auszufüllen, wenn sie zutreffend sind."
      }
    },
    tax: {
      childTaxCreditCount: "Anzahl der Kinderfreibeträge",
      general: "Steuern",
      taxIdentNumber: "Steuer-ID",
      taxClass: {
        general: "Steuerklasse",
        one: "EINS",
        two: "ZWEI",
        three: "DREI",
        four: "VIER",
        five: "FÜNF",
        six: "SECHS"
      }
    }
  },
  sex: {
    general: "Geschlecht",
    male: "männlich",
    female: "weiblich",
    diverse: "divers",
    undefined: "unbestimmt"
  },
  enum: {
    confirmationStatus: {
      CONFIRMED: "Bestätigt",
      UNCONFIRMED: "Unbestätigt"
    },
    orderType: {
      INV: "Inventuren",
      DRY: "Trockensortiment",
      BEV: "Getränke",
      MOD: "Umbauten",
      MIS: "Sonstige",
      MFR: "Spiegelflatrate"
    },
    employmentType: {
      FUL: "Vollzeit",
      MIN: "Geringfügig",
      SHO: "Kurzfristig",
      WORKING_STUDENT: "Werkstudent"
    },
    wageType: {
      SAL: "Gehalt",
      WAG: "Stundenlohn"
    },
    worklogType: {
      WORK: "Arbeit",
      PAUSE: "Pause",
      TRAVEL: "Reise",
      PASSENGER: "Mitfahrer"
    }
  },
  timeclock: {
    addEmployee: "Mitarbeiter hinzufügen",
  },
  authorization: {
    authorityGroups: {
      "Accounting": {
        title: "Abrechnung"
      },
      "Authorization": {
        title: "Rechte"
      },
      "Customer": {
        title: "Kunden",
      },
      "Documentation": {
        title: "Dokumente"
      },
      "Employee": {
        title: "Mitarbeiter"
      },
      "MonthEndClosing": {
        title: "Monatsabschluss"
      },
      "Order": {
        title: "Aufträge"
      },
      "PerDiem": {
        title: "Zuschüsse"
      },
      "Registration": {
        title: "Registrierungen"
      },
      "Statistic": {
        title: "Statistik"
      },
      "Timeclock": {
        title: "Stempeluhr"
      },
      "TimeControlling": {
        title: "Zeitkontrolle"
      },
      "Inventory": {
        title: "Inventuren"
      }
    },
    assignAuthority: {
      title: "Recht zuweisen",
      text: "Soll das Recht <b>{0}</b> zugewiesen werden?"
    },
    assignSuccess: "Recht zugewiesen",
    unassignAuthority: {
      title: "Recht entfernen",
      text: "Soll das Recht <b>{0}</b> entfernt werden?"
    },
    unassignSuccess: "Recht entfernt",
    customer: {
      title: "Kunden",
      description: "Anzeigen und Bearbeiten der Kunden",
      permissions: {
        title: "Kundenberechtigungen",
        description: "Kundenberechtigungen",
        view: {
          title: "Kunden anzeigen",
          description: "Kunden anzeigen",
        },
        edit: {
          title: "Kunden bearbeiten",
          description: "Kunden bearbeiten"
        }
      }
    }
  },
  statistic: {
    cashFlow: "Cashflow",
    employeeMonthOverview: "Monatsübersicht",
    monthlyStatistics: "Monatsstatistiken",
    twelveMonthsView: "12-Monatsansicht",
    reports: {
      inventoryPerformancesReport: {
        title: "Inventurperformance",
        description: "Bericht über die Performance pro Inventur in einem Zeitraum."
      },
      workingHoursReport: {
        title: "Arbeitszeitbericht",
        description: "Bericht aller geleisteten Arbeitszeiten in einem Monat."
      },
      generateReportButton: "Bericht erzeugen",
      reportGenerationInProgress: "Report wird erstellt..."
    },
    performance: {
      table: {
        header: {
          employeeId: "Mitarbeiter ID",
          employeeName: "Mitarbeiter Name",
          companyLocation: "Standort",
          workingMinutes: "Inventurarbeitszeit",
          positions: "Positionen",
          positionsPerHour: "Pos/h",
          standardDeviation: "Standartabweichung (Pos/h)",
          median: "Median (Pos/h)"
        }
      }
    },
    tabs: {
      ordersAndProjects: "Märkte/Projekte",
      employees: "Mitarbeiter",
      reports: "Reports",
      performance: "Performance"
    }
  },
  sum: "Summe",
  close: "Schließen",
  edit: "Bearbeiten",
  yes: "Ja",
  no: "Nein",
  rights: "Rechte",
  value: "Wert",
  worklogs: "Arbeitszeiten",
  workingTime: "Arbeitszeit",
  worklogType: "Arbeitsart",
  inventories: {
    tabs: {
      administration: "Administration",
      subtabs: {
        companyLocations: "Standorte",
        marketAreas: "Marktbereiche",
        tasksEmployee: "Aufgaben",
        tasksManager: "Leiteransicht"
      },
      competencies: "Einsatzfelder",
      countingPerformance: "Zählperformance",
      tasks: "Aufgaben"
    },
    tasks: {
      stats: {
        positions: "Positionen",
        startTime: "Einstempelzeit",
        workTime: "Arbeitszeit",
        countingSpeed: "Pos/h",
        timeLeft: "Restzeit"
      },
      filters: {
        allOrders: "Alle Aufträge",
        order: "Auftrag",
        employee: "Mitarbeiter",
        allEmployees: "Alle Mitarbeiter"
      },
      pending: {
        title: "Aufgaben - zu erledigen",
        noData: "Keine Aufgaben zu erledigen"
      },
      completed: {
        title: "Aufgaben - erledigt",
        noData: "Keine erledigten Aufgaben"
      },
      table: {
        order: "Auftrag",
        label: "Bereich",
        positions: "Positionen",
        employee: "Mitarbeiter",
        completedDate: "Erledigt am"
      },
      dialog: {
        title: {
          edit: "Aufgabe bearbeiten: ",
          create: "Neue Aufgabe",
        },
        card: {
          title: {
            general: "Allgemein"
          }
        },
        form: {
          general: {
            order: "Auftrag",
            label: "Bereich",
            positions: "Positionen",
            employee: "Mitarbeiter",
            priority: "Priorität"
          }
        },
        checkTask: "Wollen Sie die Aufgabe als 'erledigt' markieren?",
        uncheckTask: "Wollen Sie die Aufgabe als 'zu erledigen' markieren?"
      },
      message: {
        success: {
          create: "Aufgabe erstellt"
        },
        error: {
          create: "Aufgabe konnte nicht erstellt werden",
          edit: "Aufgabe konnte nicht bearbeitet werden"
        }
      },
      validation: {
        orderId: "Auftrag ist erforderlich",
        label: "Bereich ist erforderlich",
        positions: "Positionen sind erforderlich",
        employeeId: "Mitarbeiter ist erforderlich",
      },
      misc: {
        oclock: "Uhr"
      }
    },
    companyLocations: {
      title: "Standorte",
      tooltip: {
        create: "Standort anlegen",
        edit: "Standort bearbeiten",
        delete: "Standort löschen"
      },
      table: {
        header: {
          name: "Name",
          street: "Straße",
          zip: "PLZ",
          city: "Stadt",
        }
      },
      dialog: {
        title: {
          create: "Standort anlegen",
          edit: "Standort bearbeiten: ",
          delete: "Standort löschen: "
        },
        card: {
          title: {
            general: "Allgemein",
            address: "Adresse"
          }
        },
        form: {
          general: {
            name: "Name",
            notes: "Notizen"
          },
          address: {
            street: "Straße",
            zip: "PLZ",
            city: "Stadt",
          }
        },
        text: {
          delete: "Soll der Standort wirklich gelöscht werden?"
        }
      },
      message: {
        success: {
          create: "Standort erstellt"
        },
        error: {
          create: "Standort konnte nicht erstellt werden",
          edit: "Standort konnte nicht bearbeitet werden"
        }
      },
      validation: {
        name: "Name ist erforderlich"
      }
    },
    marketAreas: {
      title: "Marktbereiche",
      tooltip: {
        create: "Marktbereich anlegen",
        edit: "Marktbereich bearbeiten",
        delete: "Marktbereich löschen"
      },
      table: {
        header: {
          name: "Name",
          countingSpeedDeviation: "Zählgeschwindigkeitsabweichung (in %)"
        }
      },
      dialog: {
        title: {
          create: "Marktbereich anlegen",
          edit: "Marktbereich bearbeiten: ",
          delete: "Marktbereich löschen: "
        },
        card: {
          title: {
            general: "Allgemein"
          }
        },
        form: {
          general: {
            name: "Name",
          }
        },
        text: {
          delete: "Soll der Marktbereich wirklich gelöscht werden?"
        }
      },
      message: {
        success: {
          create: "Marktbereich erstellt"
        },
        error: {
          create: "Marktbereich konnte nicht erstellt werden",
          edit: "Marktbereich konnte nicht bearbeitet werden"
        }
      },
      validation: {
        name: "Name ist erforderlich"
      }
    },
    marketAreaCategories: {
      tooltip: {
        create: "Unterkategorie anlegen",
        edit: "Unterkategorie bearbeiten",
        delete: "Unterkategorie löschen"
      },
      table: {
        empty: "Keine Unterkategorien vorhanden"
      },
      dialog: {
        title: {
          create: "Unterkategorie anlegen",
          edit: "Unterkategorie bearbeiten: ",
          delete: "Unterkategorie löschen: "
        },
        card: {
          title: {
            general: "Allgemein"
          }
        },
        form: {
          general: {
            marketAreaName: "Teil des Marktbereiches",
            name: "Name",
            countingSpeedDeviation: "Zählgeschwindigkeitsabweichung (in %)"
          }
        },
        text: {
          delete: "Soll die Unterkategorie wirklich gelöscht werden?"
        }
      },
      message: {
        success: {
          create: "Unterkategorie erstellt"
        },
        error: {
          create: "Unterkategorie konnte nicht erstellt werden",
          edit: "Unterkategorie konnte nicht bearbeitet werden"
        }
      },
      validation: {
        name: "Name ist erforderlich"
      }
    },
    competencies: {
      table: {
        employeeId: "ID",
        firstName: "Vorname",
        lastName: "Nachname",
        companyLocation: "Standort",
        fruitAndVegetables: "O&G",
        checkout: "Kasse",
        flowers: "Floristik",
        storage: "Lager",
        storageManagement: "Lagerleitung",
        weigh: "Waage",
        reuse: "Mehrweg",
        check: "Prüfen",
        targetActualComparison: "Soll/IST",
        allrounder: "Allrounder",
        quickCounter: "Schnellzähler"
      }
    },
    countingPerformance: {
      table: {
        header: {
          day: "Wochentag",
          date: "Datum",
          name: "Auftragsname",
          workingHours: "Arbeitszeit",
          positions: "Positionen",
          positionsPerHour: "Pos/h",
          positionsPerHourInventory: "Pos/h Gesamtinventur"
        },
        total: "Gesamt"
      }
    }
  },
  positions: "Positionen",
  userProfile: {
    changePassword: "Passwort ändern",
    lastLogin: "Letzter Login",
    logout: "Abmelden",
    title: "Benutzerprofil",
    username: "Benutzername"
  }
};
