
  import {computed, defineComponent, onMounted, reactive, toRefs, watch, inject} from "vue";
  import {OrderTask} from "@/model/OrderTaskImport";
  import OrderTaskService from "@/service/OrderTaskService";
  import PageUtils from "@/components/PageUtils";
  import {employeeId} from "@/store/StoreUtils";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import InventoriesTasksDialog from "./dialog/InventoriesTasksDialog.vue";
  import {isAuthorized, t} from "@/mixin/mixins";
  import InventoriesTasksTable from "./InventoriesTasksTable.vue";
  import SwitchView from "@/components/SwitchView.vue";
  import FabButton from "@/components/FabButton.vue";
  import InventoriesTasksList from "./InventoriesTasksList.vue";
  import StatisticService from "@/service/StatisticService";
  import { EmployeesDailyStatistic } from "@/model/EmployeesDailyStatistic";
  import moment from "moment";

  export default defineComponent({
    name: "InventoriesTasks",
    components: {
      SwitchView,
      InventoriesTasksTable,
      InventoriesTasksList,
      InventoriesTasksDialog,
      FabButton
    },
    setup() {
      const state = reactive({
        stats: null as EmployeesDailyStatistic | null,
        tasks: [] as OrderTask[],
        orderId: null as number | null | undefined,
        employeeId: null as number | null | undefined,
        initialized: false as boolean
      });

      let appRoot = inject("app-root");
      // @ts-ignore
      const confirmDialog = appRoot.confirmDialogComposition as InstanceType<typeof ConfirmDialogComposition>;

      onMounted(() => {
        findAllOrderTasks();

        eventBus.$on(MessageTypes.TASKS_RELOAD, () => {
          findAllOrderTasks();
        });

        eventBus.$on(MessageTypes.TASK_UPDATED, () => {
          eventBus.$emit(MessageTypes.TASKS_RELOAD);
        });
        eventBus.$on(MessageTypes.CHECK_TASK, (task: OrderTask) => {
          onCheckTask(task);
        });

        eventBus.$on(MessageTypes.TASK_CREATED, () => {
          eventBus.$emit(MessageTypes.TASKS_RELOAD);
        });
      });

      const isManager = () => {
        return isAuthorized("Inventory_ViewTasksManager");
      };

      const userCanGetStats = (employeeIds: number[]) => {
        if (isManager()) {
          return true;
        } else {
          if (employeeIds?.length == 1) {
            const currentUserEmployeeId = parseInt(employeeId.value);
            return employeeIds.includes(currentUserEmployeeId);
          } else {
            return false;
          }
        }
      };

      const findAllOrderTasks = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0];
        OrderTaskService.findAllOrderTasks(formattedDate)
          .then((response) => {
            // @ts-ignore
            state.tasks = PageUtils.extractCollection(response.data, "orderTasks");
          })
          .catch((error) => {
            console.error("Error fetching tasks", error);
          });
      };

      const filteredTasksOrder = computed(() => {
        let filtered = state.tasks.slice(0);
        if (state.orderId) {
          filtered = filtered.filter((task: OrderTask) => {
            return task.orderId === state.orderId;
          });
        }
        return filtered;
      });

      const filteredTasks = computed(() => {
        let filtered = filteredTasksOrder.value.slice(0);
        if (state.employeeId) {
          filtered = filtered.filter((task: OrderTask) => {
            return task.employeeId === state.employeeId;
          });
        }
        return filtered;
      });

      const sortTasks = (tasks: OrderTask[]) => {
        return tasks.sort((a, b) => {
          if (a.priority === 0) return 1;
          if (b.priority === 0) return -1;
          // @ts-ignore
          return a.priority - b.priority;
        });
      };

      const pendingTasks = computed(() =>
        sortTasks(filteredTasks.value.filter((task: OrderTask) => !task.completed))
      );

      const completedTasks = computed(() =>
        sortTasks(filteredTasks.value.filter((task: OrderTask) => task.completed))
      );

      const orderFilterOptions = computed(() => {
        const options = Array.from(
          new Map(state.tasks.map((task: OrderTask) => [task.orderId, {
            label: task.orderName,
            orderId: task.orderId
          }])).values()
        );

        options.unshift({
          label: t("inventories.tasks.filters.allOrders"),
          orderId: null
        });

        return options;
      });

      const employeeFilterOptions = computed(() => {
        const options = Array.from(
          new Map(filteredTasksOrder.value.map((task: OrderTask) => [task.employeeId, {
            label: task.employeeName,
            employeeId: task.employeeId
          }])).values()
        );

        options.unshift({
          label: t("inventories.tasks.filters.allEmployees"),
          employeeId: null
        });
        return options;
      });

      watch(() => employeeFilterOptions, () => {
        const employeeIds = employeeFilterOptions.value.map(option => option.employeeId);
        if (state.employeeId && !employeeIds.includes(state.employeeId)) {
          state.employeeId = null;
        }

        if (!state.initialized) {
          const myId = parseInt(employeeId.value);
          if (employeeIds.includes(myId)) {
            state.employeeId = myId;
          }
          state.initialized = true;
        }

      }, { deep: true });

      const pendingPos = computed(() => {
        let pos = 0;
        pendingTasks.value.map((task: OrderTask) => {
          // @ts-ignore
          pos += task.positions;
        });
        return pos;
      });

      const completedPos = computed(() => {
        let pos = 0;
        completedTasks.value.map((task: OrderTask) => {
          // @ts-ignore
          pos += task.positions;
        });
        return pos;
      });

      const getDailyStatistic = () => {
        state.stats = null;

        let employeeIds: number[] = [];
        filteredTasks.value.forEach((task: OrderTask) => {
          if (task.employeeId && !employeeIds.includes(task.employeeId)) {
            employeeIds.push(task.employeeId);
          }
        });

        if (userCanGetStats(employeeIds)) {
          let orderIds: number[] = [];
          filteredTasks.value.forEach((task: OrderTask) => {
            if (task.orderId && !orderIds.includes(task.orderId)) {
              orderIds.push(task.orderId);
            }
          });

          const currentDate = new Date();
          const formattedDate = currentDate.toISOString().split("T")[0];
          StatisticService.getDailyStatistic(orderIds, employeeIds, formattedDate)
            .then((response) => {
              state.stats = response.data;
              if (state.stats) state.stats.firstClockInTime = state.stats.firstClockInTime?.substring(0, 5);

              // In case we have no end time we simply use the current time as an endtime to calculate the minutes
              // from the start
              if (state.stats?.minutesToday == 0) {
                let minutes = moment().diff(moment(state.stats?.firstClockInTime, "HH:mm"), "minutes");
                state.stats.minutesToday = minutes;
              }
            });
        }
      };

      const minutesLeftPast = computed(() => {
        if (pendingPos?.value && state?.stats?.avgPosPerHourPast) {
          const pos = pendingPos.value;
          const avg = state.stats.avgPosPerHourPast;

          // Divide by the number of selected employees
          let denominator = (!!state.employeeId) ? 1 : employeeFilterOptions.value.length;
          return Math.round(((pos / avg) * 60) / denominator);
        } else {
          return null;
        }
      });

      const avgPosPerHourToday = computed(() => {
        if (completedPos?.value && state?.stats?.minutesToday) {
          return state.stats.minutesToday > 0
            ? completedPos.value / (state.stats.minutesToday / 60)
            : 0;
        } else {
          return null;
        }
      });

      const minutesLeftToday = computed(() => {
        if (pendingPos?.value && avgPosPerHourToday?.value) {
          const pos = pendingPos.value;
          const avg = avgPosPerHourToday.value;

          // Divide by the number of selected employees (subtract 1 for the "all employees" option)
          let denominator = (!!state.employeeId) ? 1 : employeeFilterOptions.value.length - 1;
          return Math.round(((pos / avg) * 60) / denominator);
        } else {
          return null;
        }
      });

      const avgPosDeviation = computed(() => {
        if (state.stats?.avgPosPerHourPast && avgPosPerHourToday?.value) {
          const deviation = ((avgPosPerHourToday.value - state.stats.avgPosPerHourPast) / state.stats.avgPosPerHourPast) * 100;
          return Math.round(deviation * 10) / 10;
        } else {
          return null;
        }
      });

      const onCheckTask = (task: OrderTask) => {
        if (task) {
          const title = `${task.label}\n${task.employeeName}`;
          const text = t("inventories.tasks.dialog." + (task.completed ? "uncheckTask" : "checkTask"));
          confirmDialog.open(title, text)
            .then((confirmResult: boolean) => {
              if (confirmResult) {
                toggleTask(task);
              }
            });
        }
      };

      const toggleTask = (task: OrderTask) => {
        const updatedTask: OrderTask = {...task};
        updatedTask.completed = !updatedTask.completed;
        updatedTask.completedDate = updatedTask.completed
          ? new Date().toISOString()
          : null;

        OrderTaskService.updateOrderTask(updatedTask)
          .then((response) => {
            if (response.status == 200) {
              const index = state.tasks.findIndex((t: OrderTask) => t.id === task.id);
              if (index !== -1) {
                state.tasks.splice(index, 1, updatedTask);
                eventBus.$emit(MessageTypes.TASKS_RELOAD);
              }
            }
          })
          .catch((error) => {
            console.error("Error updating task:", error);
          });
      };

      watch(() => pendingTasks, () => {
        getDailyStatistic();
      }, { deep: true });

      const toTimeFormat = (minutes: number) => {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        const hour = h < 10 ? "0" + h : h;
        const minute = m < 10 ? "0" + m : m;
        return hour + ":" + minute;
      };

      const currentEmployeeId = () => {
        return parseInt(employeeId.value);
      };

      const onOpenTaskDialog = (task: OrderTask) => {
        const orders = orderFilterOptions.value;
        eventBus.$emit(MessageTypes.OPEN_TASK_DIALOG, task, orders.slice(1, orders.length));
      };

      return {
        ...toRefs(state),
        isManager,
        pendingTasks,
        completedTasks,
        pendingPos,
        completedPos,
        minutesLeftPast,
        minutesLeftToday,
        avgPosPerHourToday,
        avgPosDeviation,
        orderFilterOptions,
        employeeFilterOptions,
        toTimeFormat,
        currentEmployeeId,
        onOpenTaskDialog
      };
    },
  });
